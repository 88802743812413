import axios from "axios";

const vtmsUrl = "/v2/api/vtms";

class iVClientService {
  // Create Ivy Activation
  static createIvyActivation(activationDetails) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.post(`${vtmsUrl}/clients`, {
          boston_contact_id: activationDetails.contact_id,
          ...activationDetails,
        });
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Ivy Activations
  static getIvyActivations(pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          `${vtmsUrl}/clients`,
          `?clientType=SW900V4`,
          `&page=${pagingOpts.currentPage || 1}`,
          `&limit=${pagingOpts.pageSize || 50}`,
          `&orderBy=${pagingOpts.field || "created_at"}`,
          `&order=${pagingOpts.isAsc ? "asc" : "desc" || "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Search Ivy Activations
  static searchIvyActivations(searchTerm, pagingOpts) {
    return new Promise(async (resolve, reject) => {
      try {
        pagingOpts = pagingOpts || {};

        let url = [
          `${vtmsUrl}/clients`,
          `?clientType=SW900V4`,
          `&searchTerm=${searchTerm}`,
          `&page=${pagingOpts.currentPage || 1}`,
          `&limit=${pagingOpts.pageSize || 50}`,
          `&orderBy=${pagingOpts.field || "created_at"}`,
          `&order=${pagingOpts.isAsc ? "asc" : "desc" || "desc"}`,
        ].join("");
        let result = await axios.get(url);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Get Ivy Activation By Id
  static getIvyActivationById(clientId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(`${vtmsUrl}/clients/${clientId}`);
        resolve(result.data);
      } catch (error) {
        reject(error.response);
      }
    });
  }

  // Set Ivy Activation Status
  static setIvyActivationsStatus(clientId, paymentStatus) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${vtmsUrl}/clients/${clientId}/metadata`;
        await axios.put(url, {
          payment_status: paymentStatus,
          payment_status_updated_at: Date.now().toString(),
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // Set Ivy Activation Status
  static setIvyActivationsProductCodes(clientId, productCodes) {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.put(`${vtmsUrl}/clients/${clientId}/metadata`, {
          activated_product_codes: productCodes,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  // Get Device Properties
  static getClientDeviceProperties(clientId) {
    return new Promise(async (resolve, reject) => {
      try {
        let result = await axios.get(
          `${vtmsUrl}/clients/${clientId}/device/properties`
        );
        resolve(result.data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default iVClientService;
